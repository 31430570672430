<template>

    <div></div>

    <!-- <div class="logo">
        <img alt="Pickgeo logo" src="../assets/PickgeoGreen.svg">
    </div> -->

    <!-- <HomeContent />

    <FooterComponent /> -->

</template>


<script>
    // import HomeContent from '../components/HomeContent.vue'
    // import FooterComponent from '../components/Footer.vue'


    export default {
        name: 'HomePage',
        components:{
            // HomeContent,
            // FooterComponent
        },
        mounted() {

            this.$gtag.event(`home_page_loaded`, {
                action: `home_page_loaded`,
                category: 'pageloaded',
                label: `home_page_loaded`,
            })

        }
    }
</script>


<style scoped>

    .logo img {
        width: auto;
        height: 40px;
        margin: 30px auto 0 auto;
    }


    @media (min-width: 992px) {
        .logo img {
            height: 70px ;
        }
    }
    
</style>
