<template>

    <div class="area-info">

        <article>
            <font-awesome-icon icon="location-dot" />
            <p> {{ address }}</p>
        </article>

        <div class="area-config-info">

            <article>
                <font-awesome-icon icon="draw-polygon" v-if="areaForm == 'isochronus'" />
                <i class="fa-regular fa-circle" v-if="areaForm == 'circular'"></i>
                <p v-if="areaForm == 'circular'">Área Circular</p>
                <p v-if="areaForm == 'isochronus'">Área Isocrona</p>
            </article>

            <article v-if="areaForm == 'isochronus'">

                <span v-if="areaRoute == 'cycling'">
                    <font-awesome-icon icon="bicycle" />
                    <p>Ciclista</p>
                </span>

                <span v-if="areaRoute == 'walking'">
                    <font-awesome-icon icon="person-walking" />
                    <p>Peatón</p>
                </span>

                <span v-if="areaRoute == 'driving'">
                    <font-awesome-icon icon="car" />
                    <p>Vehículo</p>
                </span>

            </article>

            <article>
                <font-awesome-icon icon="location-dot" />
                <p v-if="areaForm == 'isochronus'">{{ areaMinutes }} minutos</p>
                <p v-if="areaForm == 'circular'">{{ areaMetres }} metros</p>
            </article>

        </div>

    </div>

</template>


<script>
    import { mapState } from 'vuex'

    
    export default {
        name: 'ConfirmationBoxInfo',
         computed: {
            ...mapState(['address', 'areaForm', 'areaRoute', 'areaMinutes', 'areaMetres'])
        },
    }
</script>


<style scoped>

    .area-info {
        background-color: white;
        border-radius: 10px;
        margin: 2% 5%;
        grid-column: 1/3;
        grid-row: 3;
        margin: 20px;
    }

    .area-info article {
        display: flex;
        align-items: center;
        padding: 1% 0;
    }

    .area-info article:first-child {
        display: flex;
        justify-content: center;
    }

    .area-config-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .area-config-info article {
        display: flex;
        flex-direction: column;
    }

    .area-info svg, i {
        font-size: 20px;
    }

    .area-info p {
        font-size: 10px;
        margin: 5px;
    }

    @media (min-width: 992px) {
        .area-info {
            border: 2px solid #00756B;
            margin: 0;
            padding: 10px;
        }
    }

</style>