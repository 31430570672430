<template>
    <n-space vertical >
        <p>{{text}}</p>
        <n-slider v-if="this.modalHeight != 100" v-model:value="this.value" :step="step" :min="this.min" :max="max" :show-tooltip="true" />
    </n-space>
</template>


<script>
    import { mapState, mapActions } from 'vuex'
    import { NSpace, NSlider} from 'naive-ui'

    export default {
        name: 'GeneralSlider',
        computed: {
            ...mapState(['areaMinutes', 'areaMetres'])
        },
        components: {
            NSpace,
            NSlider
        },
        props: {
            text: String,
            type: String,
            modalHeight: Number
        },
        data(){
            return {
                value: null,
                step: this.type == 'minutes' ? 1 : 200,
                max: this.type == 'minutes' ? 20 : 5000,
                min: this.type == 'minutes' ? 1 : 200
            }
        },
        methods: {
            ...mapActions(['setAreaMinutes', 'setAreaMetres'])
        },
        watch: {
            value(newValue) {
                this.type == 'minutes' ? this.setAreaMinutes(newValue) : this.setAreaMetres(newValue)
            }
        },
        mounted() {
            this.value = this.type == 'minutes' ? this.areaMinutes : this.areaMetres
        }
    }
</script>


<style scoped>

    .n-space p {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 10px;
    }

    .n-slider {
        width: 70%;
        margin: 45px auto 0;
    }
    
</style>