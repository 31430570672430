const getMaxMinCoordinates = (areaInfo) => {

    const polygonCoordinates = areaInfo.features[0].geometry.coordinates[0]

    let minLng = polygonCoordinates[0][0]
    let minLat = polygonCoordinates[0][1]
    let maxLng = polygonCoordinates[0][0]
    let maxLat = polygonCoordinates[0][1]

    for (let i = 1; i < polygonCoordinates.length; i++) {
        let lng = polygonCoordinates[i][0]
        let lat = polygonCoordinates[i][1]

        minLng = Math.min(minLng, lng)
        maxLng = Math.max(maxLng, lng)
        minLat = Math.min(minLat, lat)
        maxLat = Math.max(maxLat, lat)
    }

    let sw = [minLng -0.01, minLat -0.01]
    let ne = [maxLng +0.01, maxLat +0.01]

    return [sw, ne]
}

export default getMaxMinCoordinates