<template>

    <div id="area_selection">

        <SearchBar class="search-bar" />
        <MapBox />

        <div class="alert" v-show="this.showAlert">
            <span>
                <font-awesome-icon icon="xmark" />
                <h5>Error de localización</h5>
            </span>
            <p>Atención, la ubicación que ha seleccionado no pertenece a España, por lo que no podemos generar un
                informe de esa localización.</p>
        </div>

    </div>

    <GeneralButton v-if="this.address" class="area-selection-button" idName="selection_next_button"
        msg="Siguiente &nbsp;&nbsp;" link="/confirmation" type="primary" icon="angle-right" />

</template>


<script>
import { mapState, mapActions } from 'vuex'
import isPointInSpain from '@/utils/isPointInSpain'
import SearchBar from './SearchBar.vue'
import MapBox from './MapBox.vue'
import GeneralButton from './GeneralButton.vue'


export default {
    name: 'AreaSelection',
    computed: {
        ...mapState(['polygon', 'address', 'coordinates'])
    },
    components: {
        SearchBar,
        MapBox,
        GeneralButton
    },
    props: {
        screenWidth: Number,
        screenHeight: Number
    },
    data() {
        return {
            showAlert: false
        }
    },
    methods: {
        ...mapActions(['setAddress'])
    },
    watch: {
        coordinates() {
            this.showAlert = !isPointInSpain([this.coordinates.lng, this.coordinates.lat])
        }
    }
}
</script>


<style scoped>
#area_selection {
    max-height: 100%;
}

.alert {
    width: 90%;
    margin: 15px auto;
    position: absolute;
    top: 40%;
    left: 5%;
    background-color: #FFDED9;
    color: #A40007;
    border: 1px solid #A40007;
    border-radius: 10px;
    z-index: 1;
}

.alert span {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 8px 0;
}

.alert svg {
    border: 1px solid #A40007;
    padding: 3px;
    margin: 0 15px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #A40007;
    color: #FFDED9;
}

.alert h5 {
    font-weight: 600;
    font-size: 15px;
    margin: 0;
}

.alert p {
    font-size: 13px;
    line-height: 17px;
    margin: 8px 30px;
    text-align: start;
}

.area-selection-button {
    margin: 3% 25%;
    width: 50%;
    position: fixed;
    bottom: 15%;
}


@media (min-width: 992px) {
    #area_selection {
        grid-column: 1/5;
        grid-row: 2;
    }

    .alert {
        width: 45%;
        left: 13%;
    }

    .alert p {
        font-size: 15px;
    }

    .area-selection-button {
        grid-column: 5/7;
        grid-row: 1;
        position: static;
        width: 100%;
        margin: 0;
        align-self: center;
    }
}
</style>