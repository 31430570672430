import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import router from './routes'
import store from './store/store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add(fas)

const app = createApp(App).use(router)

app.use(VueGtag, {
    includes: [
        { id: 'AW-600211800' }
    ],
    config: { id: "G-09G5GGQ55K" } 
})

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(store)

app.mount('#app')
