<template>

    <span class="discount-code" @click="copyCode">

        <input type="text" id="promo_code" :value="this.$route.query.promocode">
        <p> {{ this.$route.query.promocode }} </p>
        <font-awesome-icon icon="copy" />

    </span>

</template>


<script>
export default {
    name: 'PromoCode',
    methods: {
        copyCode() {

            const path = this.$route.path.slice(1)

            this.$gtag.event(`${path}_code_button_click`, {
                action: `${path}_code_button_click`,
                category: 'buttonclick',
                label: `${path}_code_button_click`,
            })

            const copyText = document.getElementById("promo_code")

            copyText.select()
            copyText.setSelectionRange(0, 99999)
            navigator.clipboard.writeText(copyText.value)

        }
    }
}

</script>


<style>
.discount-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid #00756B;
    border-radius: 10px;
    background-color: #E6F1F0;
    width: 50%;
    max-width: 200px;
    margin: 0 auto;
}

.discount-code p {
    margin: 0;
    width: fit-content;
    padding: 3px 20px;
    font-weight: 600;
}

svg {
    font-size: 15px;
}

input {
    display: none;
}
</style>