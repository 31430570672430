<template>

    <div class="steps-indicator-container">

        <section class="step-indicator">

            <div :class="this.step > 0 ? 'step step1 active' : 'step step1'">
                <div class="step-icon"></div>
                <p>Selección</p>
            </div>

            <div class="indicator-line"></div>

            <div :class="this.step > 1 ? 'step step1 active' : 'step step1'">
                <div class="step-icon"></div>
                <p>Confirmación</p>
            </div>

            <div class="indicator-line"></div>

            <div :class="this.step > 2 ? 'step step1 active' : 'step step1'">
                <div class="step-icon"></div>
                <p>Pagar</p>
            </div>

        </section>

    </div>

</template>


<script>
    export default {
        name: 'StepsIndicator',
        props: {
            step: Number
        }
    }
</script>


<style scoped>

    .steps-indicator-container {
        max-width: 900px;
        width: 80%;
        padding: 40px 0 50px 0;
        margin: 0 auto;
    }

    .step-indicator {
        display: flex;
        align-items: center;
        padding: 0 40px;
    }

    .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 1;
    }

    .step-indicator .step-icon {
        height: 20px;
        width: 20px;
        border: 2px solid #00756B;
        border-radius: 50%;
        font-size: 10px;
        text-align: center;
        color: #ffffff;
        position: relative;
        line-height: 50px;
        font-size: 20px;
    }

    .step.active .step-icon {
        background: #00756B;    
    }

    .step p {
        text-align: center;
        position: absolute;
        bottom: -30px;
        color: #00756B;
        font-size: 14px;
        margin: 0;
    }

    .step.active p {
        color: #00756B;
    }

    .step.step2 p,
    .step.step3 p {
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;
    }

    .indicator-line {
        width: 100%;
        height: 2px;
        background: #00756B;
        flex: 1;
    }
    
</style>