<template>

    <div id="area_config">

        <p>{{ address }}</p>
        <hr v-if="address">

        <span>
            <h4>Tipo de área</h4>
            <AreaFormButtons />
        </span>

        <div class="card-container">
            <article v-if="areaForm == 'isochronus'" class="area-card">
                <h4>Forma de movimiento</h4>
                <AreaRouteButtons />

                <GeneralSlider text="Duración del trayecto en minutos" type="minutes" :modalHeight="this.modalHeight"/>
            </article>

            <article v-if="areaForm == 'circular'" class="area-card">
                <GeneralSlider text="Radio área en metros" type="metres" :modalHeight="this.modalHeight"/>
            </article>
        </div>

    </div>

</template>


<script>
    import { mapState } from 'vuex'
    import AreaFormButtons from './AreaFormButtons.vue'
    import AreaRouteButtons from './AreaRouteButtons.vue'
    import GeneralSlider from './GeneralSlider.vue'

    
    export default {
        name: 'AreaConfig',
        computed: {
            ...mapState([ 'address', 'areaForm' ])
        },
        components: {
            AreaFormButtons,
            AreaRouteButtons,
            GeneralSlider
        },
        props: {
            modalHeight: Number
        }
    } 
</script>


<style scoped>

    p {
        font-size: 12px;
        font-weight: 100;
        margin: 0;
    }

    hr {
        margin-top: 10px;
        color: #00756B;
    }

    h4 {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600;
        margin: 10px auto;
    }


    @media  (min-width: 992px) {
        #area_config {
            grid-column: 5/7;
            grid-row: 2;
            border: 2px solid #00756B;
            border-radius: 10px;
            padding: 20px;
            height: 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        p {
            font-size: 16px;
            font-weight: 600;
        }

        h4 {
            font-size: 1rem;
        }
    }
 
</style>