import getCircularArea from '../utils/getCircularArea'
import apiService from '../services/api.service'
import * as turf from '@turf/turf'


const createPolygon = async (selectedLngLat, selectedForm, selectedRouteProfile,  minutesValue, metresValue) => {

    let polygon

    if (selectedForm === 'isochronus') {
        await apiService
            .getIsochronusPolygon(selectedRouteProfile, selectedLngLat.lat, selectedLngLat.lng, minutesValue) 
            .then(({ data }) => {
                polygon = JSON.parse(data)

                if (JSON.stringify(polygon).length >= 6000) {
                    polygon = turf.simplify(polygon, { tolerance: 0.01, highQuality: false })
                }
            })
            .catch(err => console.log(err))
    } else {
        polygon = getCircularArea(metresValue, [selectedLngLat.lng, selectedLngLat.lat])
    }

    return polygon

}


export default createPolygon