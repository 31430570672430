<template>

    <div class="button-group">

        <button id="isochronus" @click="handleClick" :class="areaForm == 'isochronus' ? 'button selected' : 'button'">Área Isocrona</button>
        <button id="circular" @click="handleClick" :class="areaForm == 'circular' ? 'button selected' : 'button'">Área Circular</button>

    </div>

</template>


<script>
    import { mapState, mapActions } from 'vuex'

    
    export default {
        name: 'AreaFormButtons',
        computed: {
            ...mapState(['areaForm'])
        },
        methods: {
            ...mapActions(['setAreaForm']),
            handleClick(e) {

                this.setAreaForm(e.target.id)

                this.$gtag.event(`${e.target.id}_button_click`, {
                    action: `${e.target.id}_button_click`,
                    category: 'buttonclick',
                    label: `${e.target.id}_button_click`,
                })

            }
        }
    }
</script>


<style scoped>

    .button-group {
        display: flex;
        justify-content: center;
    }

    .button {
        font-size: 10px;
        padding: 4px 12px;
        border: 1px solid #00756B;
        border-radius: 8px;
        background-color: white;
        color: #00756B;
        margin: 0 7px;
    }
    
    .selected {
        background-color: #B0D4D1;
    }


    @media  (min-width: 992px) {
        .button {
            font-size: 14px;
        }
    }

</style>