import * as turf from '@turf/turf'
import { spainGeoJson } from './spainGeoJson'

const isPointInSpain = (coordinates) => {

    const point = turf.point(coordinates)

    const polygon = turf.multiPolygon(spainGeoJson.coordinates)

    const isInside = turf.booleanPointInPolygon(point, polygon)

    return isInside

}


export default isPointInSpain