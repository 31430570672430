<template>

    <div class="area-selection-container">

        <StepsIndicator :step="1" />

        <a href="/" id="selection_back_button" @click="emitAnalyticsEvent"> <font-awesome-icon
                icon="angle-left" />&nbsp;&nbsp;Volver</a>

        <div class="area-container" id="area_container" v-if="this.screenWidth <= 992">
            <h4>Elija una dirección y configure el área de estudio</h4>

            <AreaSelection :screenWidth="this.screenWidth" :screenHeight="this.screenHeight" />

            <BottomModal v-if="address" />
        </div>

        <div class="area-container" v-if="this.screenWidth > 992">
            <h4>Elija una dirección y configure el área de estudio</h4>

            <AreaSelection :screenWidth="this.screenWidth" :screenHeight="this.screenHeight" />

            <AreaConfig />
        </div>

    </div>

</template>

<script>
import StepsIndicator from '../components/StepsIndicator.vue'
import AreaSelection from '../components/AreaSelection.vue'
import AreaConfig from '../components/AreaConfig.vue'
import BottomModal from '../components/BottomModal.vue'
import { mapState } from 'vuex'

export default {
    name: 'SelectionPage',
    computed: {
        ...mapState(['address'])
    },
    components: {
        StepsIndicator,
        AreaSelection,
        AreaConfig,
        BottomModal
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
        }
    },
    methods: {
        updateScreen() {
            this.screenWidth = window.innerWidth
            this.screenHeight = window.innerHeight
        },
        emitAnalyticsEvent(e) {
            this.$gtag.event(`${e.target.id}_click`, {
                action: `${e.target.id}_click`,
                category: 'buttonclick',
                label: `${e.target.id}_click`,
            })
        }
    },
    mounted() {
        window.addEventListener("resize", this.updateScreen)

        this.$gtag.event(`selection_page_loaded`, {
            action: `selection_page_loaded`,
            category: 'pageloaded',
            label: `selection_page_loaded`,
        })
        // this.$gtag.event('conversion', {
        //     'send_to': 'AW-600211800/I_dqCN24wOgYENiCmp4C'
        // })
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.updateScreen)
    }
}
</script>


<style>
.area-selection-container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

a {
    text-decoration: none;
    color: #00756B;
}

h4 {
    margin: 5% 10%;
}

hr {
    margin: 0 5%;
}

.area-container {
    height: 80vh;
}

.config-button {
    position: relative;
    z-index: 1;
    bottom: 220px;
    background-color: #00756B;
}

.n-drawer {
    z-index: 1;
}


@media (min-width: 992px) {
    .area-selection-container {
        margin: 0 10%;
        width: 80%;
        overflow: visible;
    }

    a {
        display: flex;
        align-items: center;
        margin: 10px 20px;
        font-weight: 600;
        font-size: 15px;
    }

    h4 {
        align-self: flex-end;
        font-size: 2rem;
        text-align: start;
        grid-column: 1/5;
        grid-row: 1;
        margin: 0;
    }

    .area-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: max-content;
        grid-gap: 20px;
        align-items: start;
        justify-items: stretch;
        margin-bottom: 30px;
    }
}
</style>