<template>

    <div id="map"></div>

</template>


<script>
import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import getMaxMinCoordinates from '../utils/getMaxMinCoordinates'
import createPolygon from '../utils/createPolygon'
import isPointInSpain from '../utils/isPointInSpain'
import { mapActions, mapState } from 'vuex'


export default {
    name: 'MapBox',
    computed: {
        ...mapState(['polygon', 'address', 'coordinates', 'areaForm', 'areaRoute', 'areaMinutes', 'areaMetres'])
    },
    data() {
        return {
            map: null,
            marker: null
        }
    },
    methods: {
        ...mapActions(['setPolygon', 'setAddress', 'setCoordinates']),
        async initMap() {

            this.map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/zzdatalabs/cm053xmkw00ez01qwcnyb2ojw',
                center: [-4.239292, 40.239748],
                zoom: 5,
                attributionControl: false
            })
            this.map.addControl(new mapboxgl.NavigationControl())
            this.map.addControl(new mapboxgl.AttributionControl({ compact: true }))

            this.map.on('click', async (e) => {

                this.$gtag.event(`map_click`, {
                    action: `map_click`,
                    category: 'buttonclick',
                    label: `map_click`,
                })

                const selectedLngLat = {
                    lng: e.lngLat.lng,
                    lat: e.lngLat.lat
                }
                this.setCoordinates(selectedLngLat)
            })

        },
        removePolygonLayer() {

            if (this.map.getLayer('polygon-layer')) {
                this.map.removeLayer('polygon-layer')
                this.map.removeSource('polygon-layer')
            }

            if (this.map.getLayer('polygon-border-layer')) {
                this.map.removeLayer('polygon-border-layer')
                this.map.removeSource('polygon-border-layer')
            }

        },
        addPolygonLayers(geoJSON) {

            this.map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: geoJSON,
                },
                paint: {
                    'fill-color': '#006A61',
                    'fill-opacity': 0.5,
                },
            })

            this.map.addLayer({
                id: 'polygon-border-layer',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: geoJSON,
                },
                paint: {
                    'line-color': '#00756B',
                    'line-width': 1.5,
                },
            })

        },
        async drawPolygon() {

            let polygon = await createPolygon(this.coordinates, this.areaForm, this.areaRoute, this.areaMinutes, this.areaMetres)
            this.setPolygon(polygon)

            this.removePolygonLayer()
            this.addPolygonLayers(polygon)

            const polygonCoordinates = getMaxMinCoordinates(polygon)
            this.map.fitBounds(polygonCoordinates)

        }
    },
    watch: {
        async coordinates() {

            axios
                .get('https://maps.googleapis.com/maps/api/geocode/json?latlng='
                    + this.coordinates.lat + ',' + this.coordinates.lng
                    + '&key=AIzaSyBhvQseKvw7t0IzAXw20ElTgeEBzoHK0o8'
                )
                .then(async response => {

                    if (response.data.error_message) console.log(response.data.error_message)
                    else {
                        const address = response.data.results[0].formatted_address

                        if (isPointInSpain([this.coordinates.lng, this.coordinates.lat])) {
                            this.setAddress(address)

                            this.marker && this.marker.remove()
                            this.marker = new mapboxgl.Marker({ color: '#00756B' }).setLngLat([this.coordinates.lng, this.coordinates.lat]).addTo(this.map)
                            this.map.flyTo({ center: this.coordinates, zoom: 12 })

                            let polygon = await createPolygon(this.coordinates, this.areaForm, this.areaRoute, this.areaMinutes, this.areaMetres)
                            this.setPolygon(polygon)
                            this.removePolygonLayer()
                            this.addPolygonLayers(polygon)

                            const polygonCoordinates = getMaxMinCoordinates(polygon)
                            this.map.fitBounds(polygonCoordinates)
                        }
                        else this.setAddress(null)
                    }

                })
                .catch(err => console.log(err))

        },
        async areaForm() {

            this.coordinates && this.drawPolygon()

        },
        async areaRoute() {

            this.coordinates && this.drawPolygon()

        },
        async areaMinutes() {

            this.coordinates && this.drawPolygon()

        },
        async areaMetres() {

            this.coordinates && this.drawPolygon()

        }
    },
    mounted() {

        mapboxgl.accessToken = "pk.eyJ1IjoienpkYXRhbGFicyIsImEiOiJjbG4xcnh1ejgwMXc1MmlsN3M4Z3V0bnN0In0.QjfCH1DVdEu7dOxgMip5vg"
        this.initMap()

    }
}
</script>


<style scoped>
#map {
    height: 75vh;
    z-index: 0;
    bottom: 70px;
}


@media (min-width: 500px) {
    #map {
        height: 90vh;
        width: 100%;
    }
}


@media (min-width: 992px) {
    #map {
        border-radius: 10px;
        bottom: 55px;
        height: 550px;
    }
}
</style>
