import {createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import SelectionPage from '../views/SelectionPage.vue'
import ConfirmationPage from '../views/ConfirmationPage.vue'
import SuccessPage from '../views/SuccessPage.vue'
import ErrorPage from '../views/ErrorPage.vue'
import Error404Page from '../views/Error404Page.vue'


const routes = [
  {
    path: '/',
    component: HomePage,
    beforeEnter: () => {
      window.location.href = "https://www.pickgeo.com/informes";
    }
  },
  {
    path: '/area-selection',
    component: SelectionPage,
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: ConfirmationPage
  },
  {
    path: '/success',
    component: SuccessPage,
  },
  {
    path: '/error',
    component: ErrorPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404Page,
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})


export default router