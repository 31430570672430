<template>

    <div class="modal-container" :style="{ height: `${this.modalHeight}px` }">

        <font-awesome-icon v-if="this.modalHeight == 100" icon="angle-up" @click="changeHeight"/>
        <font-awesome-icon v-if="this.modalHeight == 310" icon="angle-down" @click="changeHeight"/>

        <AreaConfig :modalHeight="this.modalHeight"/>
    </div>

</template>


<script>
    import AreaConfig from './AreaConfig.vue'


    export default {
        name: 'BottomModal',
        components: {
            AreaConfig
        },
        data() {
            return {
                modalHeight: 100
            }
        },
        methods: {
            changeHeight() {

                this.modalHeight === 100 ? this.modalHeight = 310 : this.modalHeight = 100

            }
        }
    }
</script>


<style scoped>

    .modal-container {
        position: fixed;
        bottom: 0;
        background-color: white;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        width: 100%;
        z-index: 2;
    }

    svg {
        border: 1px solid #00756B;
        padding: 5px 15px;
        border-radius: 10px;
        margin-top: 3px;
    }
</style>