import { createStore } from 'vuex'


export default createStore({
    state: {
        polygon: null,
        address: null,
        coordinates:null,
        areaForm: 'isochronus',
        areaRoute: 'walking',
        areaMinutes: 10,
        areaMetres: 500
    },
    mutations: {
        SET_POLYGON(state, newValue) {
            state.polygon = newValue
        },
        SET_ADDRESS(state, newValue) {
            state.address = newValue
        },
        SET_COORDINATES(state, newValue) {
            state.coordinates = newValue
        },
        SET_AREA_FORM(state, newValue) {
            state.areaForm = newValue
        },
        SET_AREA_ROUTE(state, newValue) {
            state.areaRoute = newValue
        },
        SET_AREA_MINUTES(state, newValue) {
            state.areaMinutes = newValue
        },
        SET_AREA_METRES(state, newValue) {
            state.areaMetres = newValue
        },
    },
    actions: {
        setPolygon({commit}, newValue) {
            commit('SET_POLYGON', newValue)
        },
        setAddress({commit}, newValue) {
            commit('SET_ADDRESS', newValue)
        },
        setCoordinates({commit}, newValue) {
            commit('SET_COORDINATES', newValue)
        },
        setAreaForm({commit}, newValue) {
            commit('SET_AREA_FORM', newValue)
        },
        setAreaRoute({commit}, newValue) {
            commit('SET_AREA_ROUTE', newValue)
        },
        setAreaMinutes({commit}, newValue) {
            commit('SET_AREA_MINUTES', newValue)
        },
        setAreaMetres({commit}, newValue) {
            commit('SET_AREA_METRES', newValue)
        }
    }
})