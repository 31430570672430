<template>

  <n-config-provider :theme-overrides="themeOverrides">

    <router-view />
    
  </n-config-provider>

</template>


<script>
  import { NConfigProvider } from 'naive-ui'
  import { mapState } from 'vuex'


  export default {
    name: 'App',
    computed: {
      ...mapState(['polygon', 'address', 'coordinates'])
    },
    components: {
      NConfigProvider
    },
    data(){
      return {
        themeOverrides: {
          common: {
            primaryColor: "#00756B",
            primaryColorHover: "#006A61",
            secondayColor: "#fff"
          },
          Slider: {
            indicatorColor: "#00756B",
            indicatorBorderRadius: "10px",
          }
        }
      }
    }
  }
</script>


<style>

  #app {
      font-family: 'Ubuntu', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #006A61;
      margin: 0 auto;  /* Centers #app horizontally if it doesn't span the full width of its container */
      height: 100%;    /* Makes sure #app takes up the full height of its parent (body) */
      max-width: 1920px;
      justify-content: center; /* Horizontally centers child content */
      align-items: center;     /* Vertically centers child content */
      text-align: center;      /* This will center inline or inline-block children elements within #app */
  }
  
  :root {
    --primary-color: #006A61;
    --secondary-color: #fff;
    --background-color: #f7f7f7;
    --text-color: #333333;
    --n-icon-color: blue;
  }  

  * {
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 1rem;
    line-height: 22px;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 42px;
    letter-spacing: 0,5%;
  }

  h3 {
    font-size: 1.375rem;
    line-height: 26px;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 24px;
    letter-spacing: 1%;
  }


  @media (min-width: 992px) {
     h2 {
      font-size: 4rem;
      line-height: 72px;
      letter-spacing: 0,7%;
    }

    h3 {
      font-size: 2.625rem;
      line-height: 48px;
    }

    h4 {
      font-size: 2rem;
      line-height: 40px;
      letter-spacing: 1%;
    }
  }
  
</style>
