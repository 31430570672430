<template>

    <div class="error-page">

        <div class="header">
            <h2>Hemos tenido un problema</h2>
            <p>El informe no se ha generado correctamente</p>
        </div>
        
        <div class="text-info">
            <p>Le hemos devuelto automáticamente el importe que ha pagado. <br> Además, aquí abajo podrás descargar tanto la factura del estudio como la rectificativa.</p>
            
            <p>Como compensación le dejamos este código de descuento para su próxima compra:</p>
            <PromoCode/>
        </div>
        
        
        <div class="button-container">

            <NButton class="invoice-btn" @click="downloadInvoice">Descargar facura</NButton>
            
            <NButton class="invoice-btn" @click="downloadCorrectiveInvoice">Descargar factura rectificativa</NButton>
            
            <GeneralButton class="new-btn" idName="error_new_area_button" msg="Nuevo informe" link="/area-selection" type="primary" />

        </div>

    </div>

</template>


<script>
    import { NButton } from 'naive-ui'
    import GeneralButton from '@/components/GeneralButton.vue'
    import PromoCode from '../components/PromoCode.vue'


    export default {
        name: 'ErrorPage',
        components: {
            NButton,
            GeneralButton,
            PromoCode
        },
        methods: {
            downloadInvoice() {

                this.$gtag.event(`error_invoice_button_click`, {
                    action: `error_invoice_button_click`,
                    category: 'buttonclick',
                    label: `error_invoice_button_click`,
                })

            },
            downloadCorrectiveInvoice() {

                this.$gtag.event(`corrective_invoice_button_click`, {
                    action: `corrective_invoice_button_click`,
                    category: 'buttonclick',
                    label: `corrective_invoice_button_click`,
                })

            }
        },
        mounted() {

            this.$gtag.event(`error_page_loaded`, {
                action: `error_page_loaded`,
                category: 'pageloaded',
                label: `error_page_loaded`,
            })

        }
    }
</script>


<style scoped>

    .error-page {
        padding: 8% 5%;
        height: fit-content;
        overflow: hidden;
    } 

    .header {
        color: white;
        background-color: #00756B;
        border-radius: 10px;
        padding: 20px;
    }

    .header h2 {
        margin-bottom: 10px;
    }

    .header p {
        margin-top: 0;
    }

    .text-info p{
        margin: 5% 2%;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    button {
        margin: 10px;
    }

    .invoice-btn {
        padding: 10px 30px;
        background-color: white;
        color: #00756B;
        border: 1px solid #00756B;
        border-radius: 10px;
    }

    .invoice-btn:hover {
        color: #00534C;
        border: 1px solid #00534C;
    }

    .new-btn {
        width: fit-content;
        padding: 10px 30px;
    }


    @media (min-width: 992px) {
        .error-page {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }

        .header {
            grid-column: 1;
            grid-row: 1/3;
        }

        .text-info {
            grid-column: 2;
            grid-row: 1;
        }

        .button-container {
            grid-column: 2;
            grid-row: 2;
            display: grid;
            justify-items: center;
        }

        .invoice-btn {
            grid-row: 1;
        }

        .new-btn {
            grid-row: 2;
            grid-column: 1/3;
            padding: 10px 40px;
        }
    }
    
</style>