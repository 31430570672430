<template>

    <NButton :class="type" :bordered="false" @click="onClick">

        <font-awesome-icon v-if="this.icon == 'angle-left'" :icon="this.icon" />
        {{msg}}
        <font-awesome-icon v-if="this.icon && this.icon != 'angle-left'" :icon="this.icon" />

    </NButton>

</template>


<script>
    import { mapState } from 'vuex'
    import { NButton } from 'naive-ui'

    export default {
        name: 'GeneralButton',
        computed: {
            ...mapState(['areaForm'])
        },
        components: {
            NButton
        },
        props: {
            msg: String,
            link: String,
            type: String,
            icon: String,
            idName: String
        },
        methods:{
            onClick(){

                this.$router.push(this.link)

                this.$gtag.event(`${this.idName}_click`, {
                    action: `${this.idName}_click`,
                    category: 'buttonclick',
                    label: `${this.idName}_click`,
                })
            }
        }
    }
</script>


<style scoped>

    button {
        display: flex;
        padding: 1.5% 5%;
        margin: 20px auto;
        width: 100%;
        z-index: 0;
        border-radius: 10px;
    }

    img {
        height: 20px;
        width: 20px;
    }

    .primary {
        background-color: #00756B;
        color: white;
    }

    .primary:hover {
        background-color: #00534C;
        color: white;
    }

    .secondary {
        background-color: white;
        color: #00756B;
    }

    .secondary:hover {
        background-color: #B0D4D1;
        color: #00756B;
        border: 1px solid #00756B;
    }

    .secondary:active {
        background-color: #00756B;
        color: white;
    }

    .tertiary {
        background-color: white;
        color: #00756B;
        font-size: 11px;
    }

    .tertiary:hover {
        background-color:#E6F1F0;
    }

    .fourth {
        background-color: white;
        color: #00756B;
        border: 1px solid #00756B;
    }

    .fourth:hover {
        color: #00534C;
        border: 1px solid #00534C;
    }
</style>