<template>

    <div class="confirmation-container">

        <StepsIndicator :step="2"/>
        
        <div class="confirmation-content">

            <h4>Confirmación del área seleccionada</h4>
            
            <img :src="this.snapshot" alt="selected-area">

            <div class="area-info-container" v-if="this.screenWidth < 992">
                <ConfirmationBoxInfo/>

                <n-spin :show="this.isCharging">
                    <NButton class="payment-btn" type="primary" @click="togglePayment">Continuar al pago</NButton>
                </n-spin>
                
                <GeneralButton class="area-btn" idName="change_config_button" msg="Cambiar configuración" type="tertiary" link="/area-selection" />
            </div>

            <ConfirmationBoxInfo class="box-info" v-if="this.screenWidth > 992" />

            <span class="next-back-buttons" v-if="this.screenWidth > 992">
                <n-spin :show="this.isCharging">
                    <NButton class="payment-btn" type="primary" @click="togglePayment">Continuar al pago</NButton>
                </n-spin>
            
                <GeneralButton class="area-btn" idName="change_config_button" msg="Cambiar configuración" type="tertiary" link="/area-selection" />
            </span>

        </div>
    </div>
    
</template>


<script>
    import apiService from '../services/api.service'
    import { mapState } from 'vuex'
    import { NButton, NSpin } from 'naive-ui'
    import StepsIndicator from '../components/StepsIndicator.vue'
    import ConfirmationBoxInfo from '../components/ConfirmationBoxInfo.vue'
    import GeneralButton from '../components/GeneralButton.vue'

    export default {
        name: 'ConfirmationPage',
        computed: {
            ...mapState(['address', 'coordinates', 'polygon', 'areaForm', 'areaRoute', 'areaMinutes', 'areaMetres'])
        },
        components: {
            NButton,
            NSpin,
            StepsIndicator,
            ConfirmationBoxInfo,
            GeneralButton
        },
        data() {
            return {
                isCharging: false,
                screenWidth: window.innerWidth >= 1280 ? 1279 : window.innerWidth,
                screenHeight: window.innerHeight >= 1280 ? 1279 : window.innerHeight,
                snapshot: null
            }
        },
        methods: {
            togglePayment() {

                this.$gtag.event(`payment_button_click`, {
                    action: `payment_button_click`,
                    category: 'buttonclick',
                    label: `payment_button_click`,
                })

                this.isCharging = true
                
                const wellknown = require('wellknown')
                const wktString = wellknown.stringify(this.polygon.features[0])

                const documentData = {
                    "address": this.address,
                    "polygon": this.polygon,
                    "polygonWkt": wktString,
                    "coor": {
                        "lat": this.coordinates.lat,
                        "lon": this.coordinates.lng
                    },
                    "profile": {
                        "mode": this.areaForm == 'isochronus' ? this.areaRoute : 'radius',
                        "minutes": this.areaForm == 'isochronus' ? this.areaMinutes : null,
                        "radius": this.areaForm == 'isochronus' ? null : this.areaMetres
                    },
                    "mapImage": this.snapshot,
                    "cancel_url": `${window.location.origin}/area-selection`,
                    "success_url": `${window.location.origin}/success`
                }

                apiService
                    .postCheckoutPayment(documentData)
                    .then(({ data }) => {
                        this.isCharging = false
                        window.location.href = data.session_url
                    })
                    .catch(err => console.log(err))

            },
            getSnapshot() {

                if (this.polygon.features[0].properties) {
                    this.polygon.features[0].properties.fillColor = "#006A61"
                    this.polygon.features[0].properties.fill = "#006A61"
                    this.polygon.features[0].properties.color = "#006A61"
                } else {
                    this.polygon.features[0].properties = {
                        fillColor: "#006A61",
                        fill: "#006A61",
                        color: "#006A61"
                    }
                }

                this.snapshot = apiService.getSnapshot(JSON.stringify(this.polygon), this.coordinates.lat, this.coordinates.lng, this.screenHeight, this.screenWidth)

            },
            updateScreen() {

                this.screenWidth = window.innerWidth
                this.screenHeight = window.innerHeight

            }
        },
        mounted() {

            this.getSnapshot()
            window.addEventListener("resize", this.updateScreen)

            this.$gtag.event(`confirmation_page_loaded`, {
                action: `confirmation_page_loaded`,
                category: 'pageloaded',
                label: `confirmation_page_loaded`,
            })

        },
        beforeUnmount() {

            window.removeEventListener("resize", this.updateScreen)

        }
    }
</script>


<style scoped>

    .confirmation-container {
        height: 100vh;
        overflow: hidden;
        width: 100%;
    }

    .confirmation-content {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .confirmation-content h4 {
        margin: 10px 20px;
        grid-column: 1/3;
        grid-row: 1
    }

    .confirmation-content img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        grid-column: 1/3;
        grid-row: 2/4;
    }

    .area-info-container {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    button {
        width: 70%;
        margin: 5px auto;
        border-radius: 10px;
    }

    
    @media (min-width: 992px) {
        .confirmation-container {
            height: fit-content;
            overflow: hidden;
            width: 100%;
        }
        .confirmation-content {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            width: 80%;
            margin: 0 auto;
        }

        .confirmation-content h4 {
            grid-column: 1/4;
            grid-row: 1
        }

        .confirmation-content img {
            grid-column: 1/7;
            grid-row: 2/5;
            margin-top: 20px;
        }

        .next-back-buttons {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-around;
            grid-column: 4/7;
            grid-row: 1;
        }

        .box-info {
            grid-column: 5/7;
            grid-row: 3/5;
            margin: 20px;
        }

        .payment-btn {
            width: 100%;
            margin: 5px auto;
            padding: 5px 50px;
            border-radius: 10px;
        }

        .area-btn {
            width: 50%;
            padding: 0;
            margin: 0 5px;
            font-size: 15px;
            font-weight: 600;
        }
    }
    
</style>
