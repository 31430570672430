<template>

    <div class="isochronus-buttons">

        <button @click="toggleRoute" id="walking" :class="areaRoute == 'walking' && 'selected'">Peatón</button>
        <button @click="toggleRoute" id="cycling" :class="areaRoute == 'cycling' && 'selected'">Ciclista</button>
        <button @click="toggleRoute" id="driving" :class="areaRoute == 'driving' && 'selected'">Vehículo</button>

    </div>

</template>


<script>
    import { mapState, mapActions } from 'vuex'


    export default {
        name: 'AreaRouteButtons',
        computed: {
            ...mapState(['areaRoute'])
        },
        methods: {
            ...mapActions(['setAreaRoute']),
            toggleRoute(e){

                const id = e.currentTarget.id
                this.setAreaRoute(id)

            }
        },
    }
</script>


<style scoped>

    .isochronus-buttons {
        display: flex;
        justify-content: center;
    }

    button {
        font-size: 10px;
        padding: 4px 12px;
        border: 1px solid #00756B;
        border-radius: 8px;
        background-color: white;
        color: #00756B;
        margin: 0 7px;
    }
    
    .selected {
        background-color: #B0D4D1;
    }

</style>